import React from 'react';
import Header from './Header/Header';
import Profile from './Profile/Profile';
import Footer from './Footer/Footer';
import ScrollDown from '../../Utilities/ScrollDown/ScrollDown';
import './Home.css';

export default function Home(props) {
  return (
    <div className="home-container" id={props.id || ""}>
      <Header />
      <Profile />
      <div className="wave-footer">
        <Footer />
      </div>
      <ScrollDown />
    </div>
  );
}