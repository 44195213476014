import React, { useState, useEffect } from "react";
import Typical from "react-typical";
import { toast } from "react-toastify";
import emailjs from "emailjs-com";
import "./ContactMe.css";

import loading from "../../Assets/contactMe/loading.gif";

import ScreenHeading from "../../Utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../Utilities/ScrollService";
import Animations from "../../Utilities/Animations";

export default function ContactMe(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [banner, setBanner] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  
  const fadeInSubscription = ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  useEffect(() => {
    return () => fadeInSubscription.unsubscribe();
  }, [fadeInSubscription]);

  const handleName = (e) => {
    setName(e.target.value);
  };
  
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  
  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (name.length === 0 || email.length === 0 || message.length === 0) {
      setBanner("Tous les champs sont requis.");
      toast.error("Tous les champs sont requis.");
      return;
    }

    setIsSubmitting(true);
    setBanner("");

    const templateParams = {
      from_name: name,
      from_email: email,
      message: message,
    };

    emailjs.send("service_8bmh9uh", "template_fxrpf1a", templateParams)
      .then((response) => {
        setBanner("Message envoyé avec succès !");
        toast.success("Message envoyé avec succès !");
        setIsSubmitting(false);
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        console.error("Erreur lors de l'envoi du message :", error);
        setBanner("Une erreur est survenue, veuillez réessayer.");
        toast.error("Une erreur est survenue, veuillez réessayer.");
        setIsSubmitting(false);
      });
  };

  return (
    <div className="main-container fade-in" id={props.id || ""}>
      <ScreenHeading title={"Me Contacter"} subHeading={"Restons en Contact!"} />
      
      <div className="central-form">
        <div className="central-form-header">
          <h2>
            <Typical 
              loop={Infinity} 
              steps={["Discutons de votre projet 💼", 3000, "Envoyez-moi un message 📧", 3000]} 
              wrapper="span" 
            />
          </h2>
          
          <div className="social-icons">
            <a 
              href="https://www.linkedin.com/in/souleimane-zeggai/" 
              target="_blank" 
              rel="noopener noreferrer" 
              aria-label="LinkedIn Profile"
            >
              <i className="fa fa-linkedin-square"></i>
            </a>
            <a 
              href="https://github.com/souleimane-z" 
              target="_blank" 
              rel="noopener noreferrer" 
              aria-label="GitHub Profile"
            >
              <i className="fa fa-github-square"></i>
            </a>
            <a 
              href="https://www.instagram.com/_souzzy_/" 
              target="_blank" 
              rel="noopener noreferrer" 
              aria-label="Instagram Profile"
            >
              <i className="fa fa-instagram"></i>
            </a>
          </div>
        </div>
        
        <div className="form-container">
          <form className="contact-form" onSubmit={submitForm}>
            <div className="form-status">{banner}</div>
            
            <div className="form-group">
              <label htmlFor="name">Nom</label>
              <input 
                type="text" 
                id="name"
                className="form-input" 
                value={name} 
                onChange={handleName} 
                placeholder="Votre nom"
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input 
                type="email" 
                id="email"
                className="form-input" 
                value={email} 
                onChange={handleEmail} 
                placeholder="Votre email"
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea 
                id="message"
                className="form-input" 
                value={message} 
                onChange={handleMessage} 
                placeholder="Votre message..."
              />
            </div>
            
            <button type="submit" className="submit-btn" disabled={isSubmitting}>
              {isSubmitting ? (
                <div className="loader">
                  <img src={loading} alt="Chargement" />
                </div>
              ) : (
                <>
                  Envoyer <i className="fa fa-paper-plane"></i>
                </>
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}