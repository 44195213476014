import React from "react";
import Typical from "react-typical";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import ScrollService from "../../../Utilities/ScrollService";
import "./Profile.css";

export default function Profile() {
  return (
    <div className="profile-container">
      <div className="profile-content">
        <div className="profile-details">
          <div className="profile-greeting">Bonjour, je m'appelle</div>
          <h1 className="profile-name">
            <span className="highlighted-text">Souleimane</span> Zeggai
          </h1>
          
          <div className="profile-role">
            <h2>
              <Typical
                loop={Infinity}
                steps={[
                  "Développeur WEB",
                  1000,
                  "Front-End",
                  1000,
                  "Référencement (SEO)",
                  1000,
                  "Accessibilité (WCAG)",
                  1000,
                  "React.JS",
                  1000,
                  "Node.JS",
                  1000,
                  "MySQL",
                  1000,
                  "MongoDB",
                  1000,
                ]}
                wrapper="span"
              />
            </h2>
            <p className="profile-role-tagline">
              Développeur passionné basé dans les Hauts-de-France, 
              spécialisé dans la création d'expériences web modernes et accessibles.
            </p>
          </div>
          
          <div className="profile-social">
            <a
              href="https://www.linkedin.com/in/souleimane-zeggai/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn Profile"
            >
              <i className="fa fa-linkedin-square"></i>
            </a>
            <a
              href="https://github.com/souleimane-z"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="GitHub Profile"
            >
              <i className="fa fa-github-square"></i>
            </a>
            <a
              href="https://www.instagram.com/_souzzy_/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram Profile"
            >
              <i className="fa fa-instagram"></i>
            </a>
          </div>
          
          <div className="profile-actions">
            <button
              className="profile-btn profile-btn-primary"
              onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
            >
              <FontAwesomeIcon icon={faEnvelope} />
              Me Contacter
            </button>
            <a 
              href="/CV_Souleimane_ZEGGAI.pdf" 
              download 
              className="profile-btn profile-btn-secondary"
            >
              <FontAwesomeIcon icon={faDownload} />
              Télécharger CV
            </a>
          </div>
        </div>
        
        <div className="profile-picture">
          <div className="profile-picture-background"></div>
        </div>
      </div>
    </div>
  );
}